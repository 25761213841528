import Quotes from "../components/Quotes"
import PressPhotos from "../components/PressPhotos"

export default function Press() {
    return (
        <>
            {/* <Quotes /> */}
            <PressPhotos />
        </>


    )
}