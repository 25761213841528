import PageTitle from "../components/PageTitle";


export default function Subscribed() {
    return (
        <><PageTitle page="Thanks for subscribing!" />
        </>


    )
}